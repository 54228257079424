<template>
  <div>
    <x-header>
      <img
        slot="headerImage"
        src="../../assets/zhongobanlie/banner.jpg"
        alt=""
      />
      <h1 slot="headerTitle">{{$store.state.navShow == true ? '“冀欧号”中欧班列——河北省首趟直通德国的中欧班列':'"Ji-ou" China-Europe Railway Express - the first direct China-Europe freight train from Hebei Province to Germany'}}</h1>
    </x-header>

    <div class="zhongobanlie_title">
      <p>
        {{$store.state.navShow == true ? 'MG集团凭借多年的国际物流供应链资源优势及在俄罗斯本土不断积累的行业和客户资源，通过专业化团队运作及和精细化管理运营模式，立足河北省石家庄国际陆港，打造了河北省首个直通德国的铁路运输线。“冀欧号”中欧班列于2021年4月17日首发开行，该线路由石家庄，经俄罗斯莫斯科、直达波兰马拉舍维奇，德国汉堡、杜伊斯堡，目前已达到常态化发行。':'With years of international logistics supply chain resource and industry and customer resources accumulated in Russia, MG Group has built the first railway transportation line directly to Germany in Hebei Province based on Shijiazhuang International land port through professional team operation and fine management and operation mode. The "Ji-Ou" China-Europe freight train was first launched on April 17, 2021. The line runs through Shijiazhuang, Moscow, Russia, Marashevich, Poland, Hamburg and Duisburg, Germany, and now is regularly issued.'}}
      </p>
      <p>
        {{$store.state.navShow == true ? 'MG集团将打造以国际铁路为重心的国际多式联运服务，打造中欧精品班列、中俄班列及中亚班列，以专业化、精品化打造中欧班列双循环模式。同时提供订舱、集装箱租赁、单证、清关及相关配套服务。':'MG Group will build international multimodal transport service with international railway as the focus, build High-quality china-Europe, China-Russia and Central Asia freight trains, and create a double-cycle model of china-Europe freight trains with specialization and quality. And we provide booking, container leasing, documents, customs clearance and related supporting services at the same time.'}}
      </p>
    </div>

    <!-- 中欧列班优势 -->
    <div class="youshi" data-aos="zoom-in-down">
      <img v-if="$store.state.navShow == true" src="../../assets/zhongobanlie/advantage.png" alt="" />
      <img v-else src="../../assets/zhongobanlie/advantageE.png" alt="" />

    </div>

    <!-- 常态化运营路线 -->
    <div class="roadLine">
      <p>{{$store.state.navShow == true ? '常态化运营路线':'Normal operation route'}}</p>
      <div class="roadLine_top">
        <div class="roadLine_top_L">
          <p>
            {{$store.state.navShow == true ? '全程11000KM，经二连浩特口岸出入境，覆盖欧洲10个国家和60多个地区。':'The whole journey is 11,000KM, and it enters and exits through Erlianhot and Manzhouli ports, covering 10 European countries and more than 60 regions.'}}
          </p>

          <div class="intro_lists">
            <div class="roadLine_intro">
              <span>
                <countTo
                  :startVal="0"
                  :endVal="11000"
                  :duration="3000"
                ></countTo>
              </span>
              <!-- <p>11000</p> -->
              <p>{{$store.state.navShow == true ? '全程(KM)':'Whole Journey(KM)'}}</p>
            </div>
            <div class="roadLine_intro">
              <span>
                <countTo :startVal="0" :endVal="10" :duration="3000"></countTo>
              </span>
              <p>{{$store.state.navShow == true ?'覆盖亚欧国家': 'Covering countries in Asia and Europe'}}</p>
            </div>
            <div class="roadLine_intro">
              <span>
                <countTo :startVal="0" :endVal="60" :duration="3000"></countTo>
              </span>
              <p>{{$store.state.navShow == true ? '覆盖亚欧地区' : 'Covering regions in Asia and Europe'}}</p>
            </div>
          </div>
        </div>
        <div class="roadLine_top_R" data-aos="fade-down-left">
          <img :src="$store.state.navShow == true ? path : pathE" alt="" />
        </div>
      </div>
      <div class="roadLine_bottom">
        <div class="roadLine_bottom_L">
          <div class="firstTable">
            <!-- :resizable="false" 防止表格左右拉动 -->
            <el-table
              class="Table"
              :data="$store.state.navShow == true ?  tableData1 : tableData1E"
              border
              style="width: 100%"
            >
              <el-table-column
                prop="name"
                :label="$store.state.navShow == true ? '石家庄高邑站-欧洲':'Shijiazhuang Gaoyi Station - Europe'"
                width="240"
                :resizable="false"
              >
              </el-table-column>
              <el-table-column
                prop="kilometer"
                :label="$store.state.navShow == true ? '里程':'Mileage'"
                width="160"
                :resizable="false"
              >
              </el-table-column>
              <el-table-column
                prop="shixiao"
                :label="$store.state.navShow == true ? '时效':'Aging'"
                width="230"
                :resizable="false"
              >
              </el-table-column>
              <el-table-column
                prop="pinci"
                :label="$store.state.navShow == true ? '频次':'Frequency'"
                width="240"
                :resizable="false"
              >
              </el-table-column>
            </el-table>
          </div>
          <!-- 铁路双循环路线 第二个表格 -->
          <div class="secondTable">
            <p class="tableTitleP">{{$store.state.navShow == true ? '铁路双循环路线':'Railway double loop route'}}</p>
            <el-table
              class="Table"
              :data="$store.state.navShow == true ? tableData2 : tableData2E"
              border
              style="width: 100%"
            >
              <el-table-column
                prop="startStation"
                :label="$store.state.navShow == true ?'起始站':'Starting station'"
                width="240"
                :resizable="false"
              >
              </el-table-column>
              <el-table-column
                prop="licheng"
                :label="$store.state.navShow == true ?'里程':'Mileage'"
                width="160"
                :resizable="false"
              >
              </el-table-column>
              <el-table-column
                prop="shixiao"
                :label="$store.state.navShow == true ?'时效':'Aging'"
                width="230"
                :resizable="false"
              >
              </el-table-column>
              <el-table-column
                prop="goBackPinCi"
                :label="$store.state.navShow == true ?'去程/回程频次':'Outbound/Return frequency'"
                width="240"
                :resizable="false"
              >
              </el-table-column>
            </el-table>
          </div>
          <!-- 其他国际铁运路线  -->
          <div class="">
            <p class="tableTitleP">{{$store.state.navShow == true ? '其他国际铁运路线':'Other international railway lines'}}</p>
            <div class="lineDiv">
              <p class="firstP">{{$store.state.navShow == true ? '上海/天津/宁波/长沙':'Shanghai/Tianjin/Ningbo/Changsha'}}</p>
              <p class="zhi">{{$store.state.navShow == true ? '至':'to'}}</p>
              <p class="thirdP">{{$store.state.navShow == true ? '莫斯科/明斯克/马拉舍维奇/杜伊斯堡/汉堡':'Moscow/Minsk/Malashevich/Duisburg/Hamburg'}}</p>
            </div>
          </div>
        </div>
        <div class="roadLine_bottom_R" data-aos="fade-left">
          <img src="../../assets/zhongobanlie/train.png" alt="" />
        </div>
      </div>
    </div>

    <!-- 提供服务 -->
    <div class="provide_service">
      <div class="servive_items" data-aos="flip-down" data-aos-duration="1000">
        <img :src="$store.state.navShow == true ? listStart : listStartE" alt="" />
        <!-- <img src="../../assets/zhongobanlie/listStart.png" alt="" /> -->
      </div>
      <div
        class="servive_items"
        v-for="(item, index) in $store.state.navShow == true ?  serviveItemsArr : serviveItemsArrE"
        :key="index"
        data-aos="flip-up"
        data-aos-duration="1000"
      >
        <div class="items_title">
          <img src="../../assets/zhongobanlie/serve.png" alt="" />
          <p>{{ item.titleWord }}</p>
        </div>
        <span>{{ item.Desc }}</span>
      </div>
      <div class="servive_itemss" data-aos="flip-up" data-aos-duration="1000">
        <div class="items_titles">
          <img src="../../assets/zhongobanlie/serve.png" alt="" />
          <p>{{$store.state.navShow == true ? '进出口班列线路设计规划':'Design and planning of import and export train routes'}}</p>
        </div>
        <span
          >{{$store.state.navShow == true ? '提供中欧、中俄、中亚班列线路优化设计方案，境内外全程运输方案':'Provide the route optimization design plan of China-Europe, China-Russia, and Central Asia trains, and the whole-process transportation plan at home and abroad'}}
        </span>
      </div>
    </div>

    <div class="footerImg">
      <img src="../../assets/pages_bottom_img.png" alt="" />
    </div>
  </div>
</template>
<script>
import xHeader from '../../components/header.vue'
import countTo from 'vue-count-to'
export default {
  components: {
    // 使用/注册引入的组件 components
    xHeader,
    countTo
  },
  data () {
    return {
      listStart: require('../../assets/zhongobanlie/listStart.png'),
      listStartE: require('../../assets/zhongobanlie/listStartE.png'),
      path: require('../../assets/zhongobanlie/path.png'),
      pathE: require('../../assets/zhongobanlie/pathE.png'),
      // 第一个表格
      tableData1: [
        {
          name: '马拉舍维奇(Mala)',
          kilometer: '9800km',
          shixiao: '站到站约14天(days)',
          pinci: '4-8次/月'
        },
        {
          name: '杜伊斯堡(DIT)',
          kilometer: '11000km',
          shixiao: '站到站约18天(days)',
          pinci: '4-8次/月'
        },
        {
          name: '汉堡(HAM)',
          kilometer: '11000km',
          shixiao: '站到站约18天(days)',
          pinci: '4-8次/月'
        },
        {
          name: '石家庄 - 莫斯科(MOS)',
          kilometer: '8000km',
          shixiao: '站到站约12天(days)',
          pinci: '4次/月'
        }
      ],
      tableData1E: [
        {
          name: 'Malasevic(Mala)',
          kilometer: '9800km',
          shixiao: 'Station to station about 14 days(days)',
          pinci: '4-8 times/month'
        },
        {
          name: 'Duisburg(DIT)',
          kilometer: '11000km',
          shixiao: 'Station to station about 18 days（days）',
          pinci: '4-8 times/month'
        },
        {
          name: 'Hamburger(HAM)',
          kilometer: '11000km',
          shixiao: 'Station to station about 18 days（days）',
          pinci: '4-8 times/month'
        },
        {
          name: 'Shijiazhuang-Moscow（MOS）',
          kilometer: '8000km',
          shixiao: 'Station to station about 12 days（days）',
          pinci: '4 times/month'
        }
      ],

      // 第二个表格
      tableData2: [
        {
          startStation: '天津 - 莫斯科',
          licheng: '8000km',
          shixiao: '站到站约14天(days)',
          goBackPinCi: '4次/月'
        },
        {
          startStation: '石家庄 - 莫斯科',
          licheng: '8000km',
          shixiao: '站到站约14天(days)',
          goBackPinCi: '4次/月'
        }
      ],
      tableData2E: [
        {
          startStation: 'Tianjin-Moscow',
          licheng: '8000km',
          shixiao: 'Station to station about 14 days(days)',
          goBackPinCi: '4 times/month'
        },
        {
          startStation: 'Shijiazhuang-Moscow',
          licheng: '8000km',
          shixiao: 'Station to station about 14 days(days)',
          goBackPinCi: '4 times/month'
        }
      ],

      // 提供服务
      serviveItemsArr: [
        {
          titleImg: '../../assets/zhongobanlie/serve.png',
          titleWord: '订舱服务',
          Desc: '提供中欧班列、中俄班列、中亚班列线路的订舱服务'
        },
        {
          titleImg: '../../assets/zhongobanlie/serve.png',
          titleWord: '集装箱租赁',
          Desc: '提供境内外去程集装箱租赁服务'
        },
        {
          titleImg: '../../assets/zhongobanlie/serve.png',
          titleWord: '清关服务',
          Desc: '提供各类进出口专业关务服务'
        },
        {
          titleImg: '../../assets/zhongobanlie/serve.png',
          titleWord: '单证服务',
          Desc: '为客户提供国际联运单证服务'
        },
        {
          titleImg: '../../assets/zhongobanlie/serve.png',
          titleWord: '国内外拖车/平板',
          Desc: '为客户提供境内外集卡车门点服务'
        },
        {
          titleImg: '../../assets/zhongobanlie/serve.png',
          titleWord: '地接服务',
          Desc: '提供方案预审，计划申请、堆区、发 运咨询等方面服务'
        },
        {
          titleImg: '../../assets/zhongobanlie/serve.png',
          titleWord: '仓库/内装/堆存',
          Desc: '提供仓库装卸、堆存、保管、交接服务务'
        }
      ],
      // 提供服务
      serviveItemsArrE: [
        {
          titleImg: '../../assets/zhongobanlie/serve.png',
          titleWord: 'Booking service',
          Desc: 'Provide booking services for China-Europe, China-Russia, and Central Asia lines'
        },
        {
          titleImg: '../../assets/zhongobanlie/serve.png',
          titleWord: 'Container leasing',
          Desc: 'Provide domestic and overseas outbound container leasing services'
        },
        {
          titleImg: '../../assets/zhongobanlie/serve.png',
          titleWord: 'Customs clearance service',
          Desc: 'Provide various import and export professional customs services'
        },
        {
          titleImg: '../../assets/zhongobanlie/serve.png',
          titleWord: 'Document service',
          Desc: 'Provide customers with international transport document services'
        },
        {
          titleImg: '../../assets/zhongobanlie/serve.png',
          titleWord: 'Domestic and foreign trailers / flatbeds',
          Desc: 'Provide customers with domestic and foreign truck door point services'
        },
        {
          titleImg: '../../assets/zhongobanlie/serve.png',
          titleWord: 'Ground service',
          Desc: 'Provide program pre-review, plan application, stack area, shipping consultation and other services'
        },
        {
          titleImg: '../../assets/zhongobanlie/serve.png',
          titleWord: 'Warehouse / Interior / Stockpiling',
          Desc: 'Provide warehouse loading and unloading, stockpiling, storage, handover services'
        }
      ]
    }
  }
}
</script>
<style lang="less" scoped>
.zhongobanlie_title {
  width: 1200px;
  margin: 110px auto 0;
  & > p {
    text-align: left;
    text-indent: 32px;
    line-height: 32px;
    font-family: Microsoft YaHei;
    color: #666666;
    &:last-child {
      // margin-top: 30px;
      margin-bottom: 85px;
    }
  }
}
//
.roadLine {
  width: var(--width1200px);
  margin: 0 auto;
  text-align: left;
  margin-top: -65px;
  & > p {
    font-size: 24px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #2e67b1;
  }
  .roadLine_top {
    // height: 300px;
    display: flex;
    justify-content: space-between;
    // align-items: center;
    // background:yellow;
    position: relative;
    .roadLine_top_L {
      & > p {
        font-family: Microsoft YaHei;
        color: #666666;
        margin-top: 20px;
      }
      & .intro_lists {
        display: flex;
        // background: gray;
        & .roadLine_intro {
          // text-align: left;
          margin: 50px 0 60px 0;
          // text-align: center;
          // background: red;
          margin-right: 70px;
          & > span {
            font-size: 50px;
            font-family: AkzidenzGroteskBQ;
            font-weight: bold;
            color: #2d6aab;
            background: linear-gradient(180deg, #3676c9 0%, #42be72 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            display: block;
          }
          & > p {
            width: 150px;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #666666;
            margin-top: 20px;
            margin-left: 5px;
          }
        }
      }
    }
    .roadLine_top_R {
      // background: red;
      & > img {
        position: absolute;
        top: 84px;
        width: 758px;
        height: 242px;
        right: -205px;
      }
    }
  }
  .roadLine_bottom {
    width: 100%;
    // height: 400px;
    // background: orange;
    display: flex;
    .roadLine_bottom_L {
      width: 915px;
      height: 100%;
      // background: purple;
      .tableTitleP {
        margin: 25px 0 20px 0;
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #2e67b1;
      }

      .lineDiv {
        width: 100%;
        height: 46px;
        // background: pink;
        display: flex;
        & > p {
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .firstP {
          width: 240px;
          height: 100%;
          background: #f8f8f8;
        }
        .zhi {
          width: 50px;
          background: #f8f8f8;
          margin: 0 5px;
        }
        .thirdP {
          width: calc(100% - 240px - 50px);
          background: #f8f8f8;
        }
      }
    }
    .roadLine_bottom_R {
      position: relative;
      & > img {
        position: absolute;
        top: 0px;
        left: -125px;
        z-index: 1;
      }
    }
  }
}
// 提供服务
.provide_service {
  width: var(--width1200px);
  height: 435px;
  margin: 123px auto 65px;
  // background: pink;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  .servive_items {
    width: 282px;
    height: 140px;
    background: #ffffff;
    box-shadow: 0px 0px 20px 0px rgba(41, 92, 169, 0.1);
    padding: 0 20px 0px 28px;
    text-align: left;
    box-sizing: border-box;
    margin-bottom: 20px;
    & > img {
      margin-left: -27px;
      margin-top: -5px;
    }
    .items_title {
      display: flex;
      align-items: flex-end;
      margin-bottom: 10px;
      & > img {
        margin: 0 10px 0 0px;
      }
      & > p {
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #2e67b1;
      }
    }
    & > span {
      font-size: 14px;
      font-family: Microsoft YaHei;
      color: #666666;
    }
    &:nth-child(1) {
      background: transparent;
      box-shadow: none;
    }
  }
  .servive_itemss {
    width: 100%;
    height: 140px;
    background: #ffffff;
    box-shadow: 0px 0px 20px 0px rgba(41, 92, 169, 0.1);
    padding: 0 20px 0px 28px;
    text-align: left;
    box-sizing: border-box;
    margin-bottom: 20px;
    & > img {
      margin-left: -27px;
      margin-top: -5px;
    }
    .items_titles {
      display: flex;
      align-items: flex-end;
      margin-bottom: 10px;
      & > img {
        margin: 0 10px 0 0px;
      }
      & > p {
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #2e67b1;
      }
    }
    & > span {
      font-size: 14px;
      font-family: Microsoft YaHei;
      color: #666666;
    }
    &:nth-child(1) {
      background: transparent;
      box-shadow: none;
    }
  }
}
</style>
<style lang="less">
// .Table{
table {
  width: 100%;
}
.el-table thead {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #333333;
  // background: orange;
}
.el-table tr {
  background: #f8f8f8 !important;
}
.el-table__header {
  width: 100% !important;
  // background: orange;
}
.el-table--border,
.el-table--group {
  border: none;
}
.el-table--border::after,
.el-table--group::after,
.el-table::before {
  background: transparent;
}
.el-table th.el-table__cell {
  background: #f8f8f8 !important;
}
.el-table__body {
  font-size: 16px !important;
  font-family: Microsoft YaHei !important;
  font-weight: 400 !important;
  color: #666666 !important;
  width: 100% !important;
  text-align: left !important;
}
.el-table td.el-table__cell,
.el-table th.el-table__cell.is-leaf {
  border-bottom: 3px solid #fff;
}
.el-table--border .el-table__cell,
.el-table__body-wrapper .el-table--border.is-scrolling-left ~ .el-table__fixed {
  border-right: 3px solid #fff;
}
// }
</style>
